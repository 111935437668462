<template>
  <div class="layout-header">
    <div class="layout-header-top">
      <!-- 添加 logo -->
      <img src="@/assets/img/app-logo-simple.png" alt="Logo" class="layout-header-logo">

      <div class="nav">
        <div class="nav-item" :class="{'active': $route.path === '/index'}" @click="gotoPath('/index')">Home</div>
        <div class="nav-item" :class="{'active': $route.path === '/browse'}" @click="gotoPath('/browse')">Browse</div>
        <div class="nav-item" :class="{'active': $route.path === '/statistics'}" @click="gotoPath('/statistics')">Statistics</div>
      </div>
      <div class="account">
        <span
          v-if="!userName"
          @click="loginClick"
          >Login in</span>
        <template v-else>
          <Dropdown @on-click="handleClick">
            <span class="i-layout-account-user">
              <Avatar class="i-layout-account-user-avatar" size="small" src="/logo.png" />
              <span class="i-layout-account-user-name ivu-ml-8">{{ userName }}</span>
            </span>
            <template #list>
              <DropdownMenu>
                <DropdownItem name="logout">
                  <Icon type="ios-log-out" />
                  <span class="ivu-ml-8">log out</span>
                </DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import SearchBasic from '@/components/SearchBasic'
import { DropdownItem } from 'view-ui-plus'
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Header',
  components: {
    SearchBasic,
    DropdownItem
},
  data () {
    return {
    }
  },
  computed: {
    ...mapState('admin/user', ['userName'])
  },
  created () {
  },
  methods: {
    ...mapActions('admin/account', ['logout']),
    gotoPath (path) {
      this.$router.push(path)
    },
    loginClick () {
      const url = process.env.VUE_APP_AUTH_URL + '/oauth/authorize?appid=' + process.env.VUE_APP_AUTH_APP_ID + '&redirect_uri=' + encodeURI(location.origin + process.env.VUE_APP_PUBLIC_PATH + 'auth') + '&scope=' + process.env.VUE_APP_AUTH_SCOPE + '&response_type=code&state=STATE'
      location.href = url
    },
    handleClick(name) {
      if (name === 'logout') {
        this.logout();
      }
    }
  }
}
</script>
<style lang="less">
</style>